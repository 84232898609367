import React from "react";
import ReactDOM from "react-dom";
import dotenv from "dotenv";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import enTranslation from "./locales/en/translation.json";
import bnTranslation from "./locales/bn/translation.json";

import store from "./store";
import "./bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

dotenv.config("./../.env");

i18n.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // language to use
  resources: {
    en: {
      translation: bnTranslation,
    },
    bn: {
      translation: enTranslation,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nextProvider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
