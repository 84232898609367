import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from "react-bootstrap";
import {
  getroductsDetails,
  createProductReview,
} from "./../../actions/supplierProduct";
import Loader from "../../components/Loader/Loader";
import Message from "../../components/Message/Message";
import Meta from "../../components/Helmet/Meta";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { createConsumer } from "../../actions/consumerProductAction";
import axios from "axios";
import toast from "react-hot-toast";

const FarmerProduct = ({ history, match }) => {
  const { t } = useTranslation(); // Use useTranslation hook to access translation function
  const userId = match.params.id;
  const dispatch = useDispatch();

  const FarmerProductDetails = useSelector(
    (state) => state.FarmerProductDetails
  );
  const { loading, error, product } = FarmerProductDetails;

  const farmerReviewCreate = useSelector((state) => state.farmerReviewCreate);
  const {
    loading: loadingReview,
    error: loadingError,
    success: successReview,
  } = farmerReviewCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (successReview) {
      // history.push('/admin/dashboard')
    } else {
      if (!product.name || product._id !== userId) {
        dispatch(getroductsDetails(userId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, match, successReview, history, userId]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    );
  };

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };
  const handleCreateConsumer = async () => {
    // console.log(product);
    const { data } = await axios.post(
      `https://agriconnectbackend.onrender.com/api/consumer/`,
      product,
      config
    );
    toast.success("Product has been accepted!");
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    );
    console.log(data);
  };
  return (
    <div className="productScreen">
      <Meta title={t("FARMER_REVIEW_TITLE")} />
      <Container>
        <Link className="btn btn-go-back btn-dark" to="/admin/supplierproducts">
          {t("GO_BACK")}
        </Link>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Row className="p-1 seed-product">
            <Col md={5}>
              <Image
                className="mx-auto image-machine"
                src={product.image}
                alt={product.name}
                width={200}
              />
            </Col>
            <Col md={4}>
              <ListGroup className="borderless" variant="flush">
                <ListGroup.Item>
                  <h4>{t("NAME")}</h4>
                  {product.name}
                </ListGroup.Item>
                <ListGroup.Item>
                  <h4>{t("ADDRESS")}</h4> {product.address}
                </ListGroup.Item>
                <ListGroup.Item>
                  <h4>{t("PRODUCT_DESCRIPTION")}</h4> {product.description}
                </ListGroup.Item>
                {product.phonenumber ? (
                  <ListGroup.Item>
                    <h4>{t("CONTACT_NUMBER")}</h4>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`https://wa.me/${product.phonenumber}`}
                    >
                      {product.phonenumber}
                    </a>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                <ListGroup.Item>
                  <Row>
                    <Col md={6}>
                      <h4>{t("CROP")}</h4>
                      {product.cropSelection}
                    </Col>
                    {product.storage ? (
                      <Col md={6}>
                        <h4>{t("QUANTITY")}</h4>
                        {product.storage} kg
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3}>
              <Card>
                <ListGroup>
                  <ListGroup.Item>
                    <p>{t("WRITE_FARMER_REVIEW")}</p>
                    {successReview && (
                      <Message variant="success">
                        {t("REVIEW_SUBMITTED_SUCCESSFULLY")}
                      </Message>
                    )}
                    {loadingReview && <Loader />}
                    {loadingError && (
                      <Message variant="danger">{loadingError}</Message>
                    )}
                    {userInfo && userInfo.isAdmin ? (
                      <Form onSubmit={submitHandler}>
                        <Form.Group controlId="rating">
                          <Form.Label>{t("RATING")}</Form.Label>
                          <Form.Control
                            as="select"
                            value={rating}
                            onChange={(e) => setRating(e.target.value)}
                          >
                            <option value="">{t("SELECT")}...</option>
                            <option value="1">1 - {t("POOR")}</option>
                            <option value="2">2 - {t("FAIR")}</option>
                            <option value="3">3 - {t("GOOD")}</option>
                            <option value="4">4 - {t("VERY_GOOD")}</option>
                            <option value="5">5 - {t("EXCELLENT")}</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="comment">
                          <Form.Label>{t("FEEDBACK")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            row="3"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <div className=" ">
                          <Button
                            disabled={loadingReview}
                            type="submit"
                            variant="primary"
                          >
                            {t("SUBMIT")}
                          </Button>
                          <Button
                            className="ml-4 bg-success"
                            disabled={loadingReview}
                            type="button"
                            onClick={handleCreateConsumer}
                            variant="primary"
                          >
                            Accept
                          </Button>
                        </div>
                      </Form>
                    ) : (
                      <Message>
                        {t("PLEASE_SIGN_IN")}{" "}
                        <Link to="/login">{t("SIGN_IN")}</Link>{" "}
                        {t("TO_WRITE_A_REVIEW")}
                      </Message>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default FarmerProduct;
