import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavDropdown, Image, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Header.css";
import { logout } from "./../../actions/userActions";

const Header = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "bn" : "en";
    i18n.changeLanguage(newLanguage);
  };

  return (
    <Navbar collapseOnSelect expand="lg" fixed="top">
      <LinkContainer to="/">
        <Navbar.Brand className="nav-cal">
          <Image width="80px" src="/Logo.png" />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <LinkContainer to="/">
            <Nav.Link className="nav-cal">{t("HOME")}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/farmer">
            <Nav.Link className="nav-cal">{t("FARMER")}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/consumer">
            <Nav.Link className="nav-cal">{t("CONSUMER")}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="login?redirect=supplier">
            <Nav.Link className="nav-cal">{t("SUPPLIER")}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/cart">
            <Nav.Link
              className={`${
                userInfo ? "remove-space" : "add-space cart nav-cal"
              } `}
            >
              <i className="fas fa-shopping-cart"></i>
              {t("CART")}
            </Nav.Link>
          </LinkContainer>
          {userInfo ? (
            <NavDropdown title={userInfo.name?.toUpperCase()} id="username">
              {userInfo.isAdmin && (
                <LinkContainer to="/admin/dashboard">
                  <NavDropdown.Item>{t("DASHBOARD")}</NavDropdown.Item>
                </LinkContainer>
              )}
              <LinkContainer to="/profile">
                <NavDropdown.Item>{t("PROFILE")}</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/login">
                <NavDropdown.Item onClick={logoutHandler}>
                  {t("LOGOUT")}
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          ) : (
            <LinkContainer to="/login">
              <Nav.Link className="login nav-cal">{t("SIGN IN")}</Nav.Link>
            </LinkContainer>
          )}
          {userInfo && userInfo.isAdmin && (
            <NavDropdown title={t("ADMIN")} id="adminmenu">
              <LinkContainer to="/admin/userlist">
                <NavDropdown.Item>{t("USERS")}</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/productlist">
                <NavDropdown.Item>{t("PRODUCTS")}</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/orderlist">
                <NavDropdown.Item>{t("ORDERS")}</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          )}
        </Nav>
        <Button variant="outline-primary" onClick={toggleLanguage}>
          {i18n.language === "en" ? "বাংলা" : "English"}
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
