import React from "react";
import { Container, Row, CardDeck, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Meta from "../../components/Helmet/Meta";
import "./FarmerStyle.css";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import i18next from "i18next";

const FarmerScreen = () => {
  const { t } = useTranslation(); // Use useTranslation hook to access translation function
  console.log(i18next.language);

  return (
    <div>
      <Meta title={t("FARMER_TITLE")} />
      <Container className="farmerContainer">
        <h1 className="title">{t("FARMER_TITLE")}</h1>
        <h4 className="farmer-title">{t("FARMER_DESCRIPTION")}</h4>
        <Row className="row-one justify">
          <CardDeck>
            <Card border="primary" style={{ width: "25rem" }}>
              <Card.Body>
                <Card.Title className="card-titile">
                  {t("PURCHASE_TITLE")}
                </Card.Title>
                <LinkContainer to="/farmers/purchaseSeeds">
                  <Button className="btn-explore btn-md m-2">
                    {t("EXPLORE_MORE")}
                  </Button>
                </LinkContainer>
              </Card.Body>
            </Card>
            <Card border="primary" style={{ width: "25rem" }}>
              <Card.Body>
                <Card.Title className="card-titile">
                  {t("SELL_TITLE")}
                </Card.Title>
                <LinkContainer to="/login?redirect=supplier">
                  <Button className="btn-explore btn-md m-2">
                    {t("EXPLORE_MORE")}
                  </Button>
                </LinkContainer>
              </Card.Body>
            </Card>
            <Card border="primary" style={{ width: "25rem" }}>
              <Card.Body>
                <Card.Title className="card-titile">
                  {t("LEND_TITLE")}
                </Card.Title>
                <LinkContainer to="/farmers/lendMachines">
                  <Button className="btn-explore btn-md m-2">
                    {t("EXPLORE_MORE")}
                  </Button>
                </LinkContainer>
              </Card.Body>
            </Card>
          </CardDeck>
        </Row>
      </Container>
    </div>
  );
};

export default FarmerScreen;
